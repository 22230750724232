export const data = [
  "DevOps",
  "agile",
  "frameworks",
  "libraries",
  "agorithm",
  "Bootstrap",
  "bug",
  "domain",
  "minification",
  "Metaverse",
  "Web3",
  "machine learning",
  "big data",
  "internet of things",
  "digital disruption",
  //ui/ux
  "user interface",
  "user experience",
  "responsive web design",
  //code speak
  "front-end",
  "back-end",
  "full-stack",
  "API",
  "Continuous Integration",
  "Continuous Delivery",
  "serverless",
  "cache",
  "command-line",
  "virtual machine",
  "object-oriented",
  "dry",
  "libraries",
  "mobile-first",
  "cloud native",
  //security
  "encryption",
  "decryption",
  "HTTPs",
  "JSON",
  "Firewall",
  "IP Address",
  "Proxy Server",
  "VPN",
   "SSL",
  "Nginx",
  "IPv4",
  "IPv6",
  "Apache",
  "software",
  "hardware",
  "firmware",
  "Jamstack",
  "Sudo",
  "Git",
  "Cron jobs",
  "environment variables",
  //programming langauges
  "Python",
  "Ruby on Rails",
  "JavaScript",
  "PHP",
  "CSS",
  "HTML",
  "jQuery",
  "MySQL",
  "C#",
  "Java",
  "Go",
  "Linux",
  "Ubuntu",
  "bash",
  "shell scripting",
  "C++",
  "Kotlin",
  "Rust",
  "Swift",
  "XML",
  "JSX",
  "JSON",
  "Docker",
  "Kubernetes",
  "Laravel",
  "AJAX",
  "NPM",
  "AWS",
  "Microsoft Azure",
  "Selenium",
  "Django",
  //frameworks
  "Spring MVC",
  "React",
  "Angular",
  "Vue",
  "Svelte",
  "Chrome V8",
  //javascript
  "DOM",
  "Node.js",
  "Dogecoin",
  "blockchain",
  "bitcoin",
  "decentralized",
  "Non-Fungible Token",
  "Data Mining",
  //people
  "Elon Musk",
  "Steve Ballmer",
  "Dan Abramov",
  "Jack Dorsey",
  "Mark Zuckerberg",
  "Steve Jobs",
];
