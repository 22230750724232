import React from "react";
import RadioGroup from "./RadioGroup";
import Input from "./Input";
import Fieldset from "./Fieldset";
import { IpsumGenerator } from "../utils/IpsumGenerator";

function IpsumForm({ setIpsum, setIsLoading, setShowForm, setShowIpsumSection}) {
  const generator = new IpsumGenerator();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const parsed = Object.fromEntries(data.entries());
    setShowForm(false);
    setIsLoading(true);
    const newIpsum = generator.generateIpsum(parsed);
    await new Promise((r) => setTimeout(r, 2000));
    setIpsum(newIpsum);
    setIsLoading(false);
    setShowIpsumSection(true);
  };

  return (
    <>
      <form className="ipsum-form center stack" onSubmit={handleSubmit}>
        <Fieldset className="stack" legend="Content" >
          <RadioGroup
            checked="words"
            groupName="content"
            buttonNames={["words", "paragraphs"]}
            errorMessage="content is required"
            required
          />
        </Fieldset>
        <Fieldset legend="Amount">
          <Input
            type="number"
            name="amount"
            errorMessage="please enter a whole number greater than 0"
            pattern="^[1-9][0-9]*$"
            required
            defaultValue="1"
            min="1"
            autoFocus
          />
        </Fieldset>
        <Fieldset className="stack" legend="Wrapper">
          <RadioGroup
            groupName="tags"
            buttonNames={["<p>", "<li>", "<div>", "<span>", '"_"', "`_`"]}
          />
        </Fieldset>
        <Fieldset className="stack" legend="Case">
          <RadioGroup
            groupName="letterCase"
            required
            checked="Sentence Case"
            errorMessage="case is required"
            buttonNames={[
              "Lowercase",
              "Uppercase",
              "Capital Case",
              "Sentence Case",
            ]}
          />
        </Fieldset>
        <button type="submit" className="btn btn--primary">
          Generate
        </button>
      </form>
    </>
  );
}

export default IpsumForm;

