import { data } from "../data/data";

export class IpsumGenerator {
  #data;

  constructor() {
    this.#data = data;
  }

  generateIpsum({ amount, content, tags, letterCase }) {
    let ipsum = "";
    if (content === "words") {
      ipsum = this.#generateIpsumSentence(amount, tags, letterCase);
    } else {
      ipsum = this.#generateIpsumParagraphs(amount, tags, letterCase);
    }
    return ipsum;
  }
  #generateIpsumSentence(amount, tags, letterCase) {
    let ipsum = this.#generateIpsumString(amount) + ".";
    ipsum = this.#changeStringCase(ipsum, letterCase);
    if (tags) {
      ipsum = this.#addTagsToString(ipsum, tags);
    }
    return ipsum;
  }
  #addTagsToString(string, tags) {
    switch (tags) {
      case '"_"':
        return '<span>"</span>' + string + '<span>"</span>';
      case "`_`":
        return "<span>`</span>" + string + "<span>`</span>";
      case "<p>":
        return `<span>&lt;p&gt;</span>${string}<span>&lt;/p&gt;</span>`;
      case "<span>":
        return `<span>&lt;span&gt;</span>${string}<span>&lt;/span&gt;</span>`;
      case "<div>":
        return `<span>&lt;div&gt;</span>${string}<span>&lt;/div&gt;</span>`;
      default:
        return `<span>&lt;li&gt;</span>${string}<span>&lt;/li&gt;</span>`;
    }
  }
  #generateIpsumString(amount) {
    const numberOfWords = parseInt(amount);
    let currentIpsumString = "";
    let currentWordCount = 0;
    while (currentWordCount !== numberOfWords) {
      let randomSelectionNum = Math.floor(Math.random() * this.#data.length);
      let randomChoice = this.#data[randomSelectionNum];
      let newIpsumString = currentIpsumString.concat(" " + randomChoice);
      currentWordCount = countWordsInString(newIpsumString);
      if (currentWordCount < numberOfWords) {
        currentIpsumString = newIpsumString;
      } else if (currentWordCount === numberOfWords) {
        currentIpsumString = newIpsumString;
        return currentIpsumString.trim();
      } else if (currentWordCount > numberOfWords) {
        currentWordCount = countWordsInString(currentIpsumString);
      }
    }
  }
  #changeStringCase(string, letterCase) {
    switch (letterCase) {
      case "Lowercase":
        string = string.toLowerCase();
        break;
      case "Uppercase":
        string = string.toUpperCase();
        break;
      case "Capital Case":
        let capitalCaseString;
        const arr = string.split(" ");
        arr.forEach((word) => {
          capitalCaseString += word[0].toUpperCase() + word.substring(1) + " ";
        });
        string = capitalCaseString.trim();
        break;
      default:
        string = capitalizeAString(string);
        break;
    }
    return string;
  }
  #generateIpsumParagraphs(amount, tags, letterCase) {
    let generatedIpsum = "";

    for (let i = 0; i < amount; i++) {
      const randomSelectionNum = Math.floor(Math.random() * 2) + 1;
      let generatedParagraph = "";

      if (randomSelectionNum === 1) {
        generatedParagraph = this.#generateIpsumSentence(30);
      } else if (randomSelectionNum === 2) {
        generatedParagraph = this.#generateMediumParagraph();
      }
      generatedParagraph = this.#changeStringCase(
        generatedParagraph,
        letterCase
      );

      if (tags) {
        generatedParagraph = this.#addTagsToString(generatedParagraph, tags);
      }
      generatedIpsum += generatedParagraph;
    }
    return generatedIpsum;
  }
  #generateMediumParagraph() {
    let mediumParagraph = "";
    const generatedIpsum = this.#generateIpsumString(60);
    const stringArray = generatedIpsum.split(" ");
    //add punctuation and capitalization
    stringArray[1] = capitalizeAString(stringArray[1]);
    stringArray.splice(9, 0, ".");
    stringArray[10] = capitalizeAString(stringArray[10]);
    stringArray.splice(17, 0, ",");
    stringArray.splice(24, 0, ",");
    stringArray.splice(31, 0, ".");
    stringArray[32] = capitalizeAString(stringArray[32]);
    stringArray.splice(46, 0, ",");
    stringArray.splice(52, 0, ".");
    stringArray[53] = capitalizeAString(stringArray[53]);
    stringArray.push(".");
    //add spaces
    for (const element in stringArray) {
      if (stringArray[element] === "." || stringArray[element] === ",") {
        mediumParagraph += stringArray[element];
      } else {
        mediumParagraph += " " + stringArray[element];
      }
    }
    return mediumParagraph.trim();
  }
}

//utilities
function countWordsInString(str) {
  const arr = str.split(" ");
  return arr.filter((word) => word !== "").length;
}

function capitalizeAString(str) {
  return (str = str[0].toUpperCase() + str.substring(1));
}

//if it is editable
