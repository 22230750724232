import React from 'react';
import ReactDOM from 'react-dom/client';
import './sass/index.scss';
import App from './App';
import "./assets/fonts/Monoid/Monoid-Regular.ttf";
import "./assets/fonts/Monoid/Monoid-Italic.ttf";
import "./assets/fonts/Monoid/Monoid-Bold.ttf";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

