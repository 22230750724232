import React, { Fragment, useRef, useEffect } from "react";

function Input({ hasError, errorMessage, autoFocus, ...inputProps }) {
  
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus])

  return (
    <Fragment>
      <input {...inputProps} ref={inputRef} />
      <span className="form-error">{errorMessage}</span>
    </Fragment>
  );
}

export default Input;
