import React from "react";

function Fieldset({ legend, children }) {
  return (
    <fieldset className="stack">
      <legend>{legend}</legend>
      {children}
    </fieldset>
  );
}

export default Fieldset;
