import React, { useState, Fragment, } from "react";
import IpsumSection from "./IpsumSection";
import IpsumForm from "./IpsumForm";

export default function IpsumGenerator() {
  const [showForm, setShowForm] = useState(true);
  const [showIpsumSection, setShowIpsumSection] = useState(false);
  const [ipsum, setIpsum] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const goBack = () => {
    setShowForm(true);
    setShowIpsumSection(false);
  };

  return (
    <>
      {showForm && (
        <IpsumForm
          setIpsum={setIpsum}
          setIsLoading={setIsLoading}
          setShowForm={setShowForm}
          setShowIpsumSection={setShowIpsumSection}
        />
      )}
      {isLoading && (
        <Fragment>
          <div class="loader center">
            <span className="cursor"></span>
          </div>
        </Fragment>
      )}
      {showIpsumSection && <IpsumSection ipsum={ipsum} goBack={goBack} />}
    </>
  );
}
