import React, { useRef, useEffect, useState } from "react";
import sound from "../assets/audio/slide-scissors.wav";

function IpsumSection({ ipsum, goBack }) {
  const divRef = useRef(null);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    divRef.current.style.height = divRef.current.scrollHeight + "px";
  }, [ipsum]);

  const copyIpsumToClipboard = () => {
    new Audio(sound).play();
    setSelected(true);
    navigator.clipboard.writeText(divRef.current.textContent);
    setTimeout(() => setSelected(false), 200);
  };

  return (
    <section className="ipsum-section center stack">
      <div className="cluster">
        <button type="button" className="btn btn--ghost" onClick={goBack}>
          back
        </button>
        <button
          type="button"
          className="btn btn--primary"
          onClick={copyIpsumToClipboard}
        >
          copy
        </button>
      </div>
      <div
        ref={divRef}
        className={`ipsum-area ${selected ? "selected" : ""}`}
        dangerouslySetInnerHTML={{ __html: ipsum }}
      ></div>
    </section>
  );
}

export default IpsumSection;
