import IpsumGenerator from "./components/IpsumGenerator";

function App() {
  return (
    <div className="app-container">
      <header>
        <h1 className="stack">
          <strong>DevSum</strong>
          <span>The ipsum for developers.</span>
        </h1>
      </header>
      <IpsumGenerator/>

      {/* <p>Checkout this Merch..</p>
      <footer>
        <a>View on Github</a>
        <p>Developer Ipsum | © 2022 All Rights Reserved</p>
      </footer> */}
    </div>
  );
}

export default App;
