import React, { Fragment, useState } from "react";

function RadioGroup({
  groupName,
  buttonNames,
  checked,
  errorMessage,
  ...radioProps
}) {

  
  const [selectedValue, setSelectedValue] = useState(checked ? checked : null);

  const [hasError, setHasError] = useState(false);

  const handleRadioClick = (e) => {
    const value = e.target.value;
    if (value === selectedValue) {
      e.target.checked = false;
      setSelectedValue(null);
      setHasError(true);
    } else {
      setSelectedValue(value);
      setHasError(false);
    }
  };

  return (
    <div className={`radio-button-group stack ${hasError ? "hasError" : ""}`}>
      <div className="cluster">
        {buttonNames.map((buttonName, index) => {
          return (
            <Fragment key={index}>
              <input
                defaultChecked={checked === buttonName ? "checked" : ""}
                type="radio"
                id={buttonName}
                name={groupName}
                value={buttonName}
                onClick={handleRadioClick}
                {...radioProps}
              />
              <label className="btn btn--ghost" htmlFor={buttonName}>
                {buttonName}
              </label>
            </Fragment>
          );
        })}
      </div>
      <span className="form-error">{errorMessage}</span>
    </div>
  );
}

export default RadioGroup;
